$unfcccBlue: #4169e2; // Azur // page elements (button, links, tabs)
$unfcccDarkBlue: #3b56a5; // Ocean // header, footer, progress bar
$unfcccDarkGrey: #424245; // Dark grey // body text, action box bg, footer bg, info alert
$unfcccBlack: #1a1a1c; // Site Title, Navbar, subheadings
$unfcccLightGrey: #f5f7f6; // Light grey // section headers bg, shadow colour
$unfcccError: #e0697a;
$unfcccBlueHoverFocus: #3251B2;

// arDominionB5Plugin overrides
$primary: $unfcccBlue;
$secondary: $unfcccDarkGrey;
$body-bg: #ffffff; // bg colour and text colour on dark bg
$link-color: $primary;
$font-family-sans-serif: "Merriweather Sans", sans-serif;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": #118754,
  "info": $unfcccBlue,
  "warning": #f5a422,
  "danger": $unfcccError,
  "light": $unfcccLightGrey,
  "unfccc-error": $unfcccError,
  "unfccc-light-grey": $unfcccLightGrey,
  "unfccc-dark-blue": $unfcccDarkBlue,
  "white": #ffffff,
);
