.atom-btn-secondary {
  background-color: $unfcccDarkBlue;
  border-color: $unfcccDarkBlue !important; // !important to override dist .navbar-toggle settings
  &:hover {
    background-color: $primary;
    border-color: $primary;
  }
  &:active {
    background-color: $unfcccBlueHoverFocus;
    border-color: $unfcccBlueHoverFocus;
  }
  &:focus {
    background-color: $primary;
    border-color: $unfcccBlueHoverFocus;
  }
}

#logos img {
  max-height: 3.5 * $spacer;
}

.clipboard-count {
  background-color: $secondary !important;
}

// header title
.display-5 {
  color: $unfcccLightGrey;
  display: table-cell;
  vertical-align: middle;
  font-size: 1.5rem;
}

.da-border-start {
  padding-left: 1rem !important;
  display: table;
  vertical-align: middle;
}

.da-border-end {
  padding-right: 1rem !important;
}

.this-site {
  @include media-breakpoint-down(md) {
    display: table;
    margin: 0 auto;
    text-align: center;
  }
}

body { // navbar icons (topBar, not homepage)
  color: $secondary !important;

  .header {
    background-color: $unfcccDarkBlue !important;
    max-width: unset;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: $white;
    &:hover, &:active, &:focus {
      color: $primary;
      background-color: $unfcccBlueHoverFocus !important;
    }
  }

  #top-bar .navbar-nav .nav-item i { // navbar icons (not homepage)
    color: $white;
    &:hover, &:active, &:focus {
      color: $white !important;
      background-color: $unfcccBlueHoverFocus !important;
    }

    .navbar-nav .nav-item .dropdown-item { // navbar dropdown
      &:hover, &:active, &:focus {
        color: $white;
        background-color: $unfcccBlueHoverFocus !important;
        text-decoration: underline $white
      }
    }
  }

  .dropdown .dropdown-menu .dropdown-item { // login dropdown
    &:hover, &:active, &:focus {
      color: $white;
      background-color: $unfcccBlueHoverFocus !important;
      text-decoration: underline $white
    }
  }
}

body.home {
  color: $secondary !important;

  > header {
    background-color: $unfcccDarkBlue !important;
  }
  
  .navbar-dark .navbar-nav .nav-link { // navbar icons (homepage)
    color: $white;
    &:hover, &:active, &:focus {
      color: $white;
      background-color: $unfcccBlueHoverFocus;
    }
  }

  .navbar-nav .nav-item .dropdown-menu li a { // navbar drop down menu (homepage)
    color: $primary;
    &:hover, &:active, &:focus {
      color: $white;
      background-color: $unfcccBlueHoverFocus;
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up(lg) {
    #search-box {
      max-width: initial;
    }
  }

  #search-box-results {
    transform: translate(0, 33px) !important;
  }

  #content {
    border: none;
  }

  .browse-menu {
    max-width: 80%;
  }

  .browse-menu, .browse-menu .list-group-item {
    font-weight: bold;

    @include media-breakpoint-down(md) {
      font-size: 15px;
    }
  }

  .browse-menu .list-group-item {
    border-left: none;
    border-right: none;
    text-transform: uppercase;
    color: $white;
  }

  .browse-menu .list-group-item-action:hover,
  .browse-menu .list-group-item-action:active,
  .browse-menu .list-group-item-action:focus {
    background-color: $unfcccBlueHoverFocus !important;
    color: $white;
    text-decoration: underline $white;
  }

  .sign-up {
    background-image: url('/plugins/arUnfcccPlugin/images/sign-up.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    a {
      background-color: rgba($unfcccBlack, 0.5);
    }
  }

  .spotlight {
    figure {
      position: relative;
    }
    @include media-breakpoint-up(sm) {
      figcaption {
        position: absolute;
      }
    }
  }

  #unfccc-carousel {
    .slick-dotted.slick-slider {
      margin: 0;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    .slick-dots {
      position: relative;
      bottom: 0;
    }
    .slick-arrow {
      top: calc(100% - 12px);
    }
    .slick-autoplay-toggle-button {
      color: #fff;
      top: calc(100% - 42px);
    }
    /* Accessible slick adds 7px for the tallest slide */
    .col-md-4 {
      background-color: $secondary;
      margin-top: -7px;
      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-bottom: 7px;
      }
    }
  }
  
  // jumbotron search bar
  .jumbotron {
    background-image: url('/plugins/arUnfcccPlugin/images/banner.jpg');
    background-position: center 80%;
    background-size: cover;
    background-repeat: no-repeat;
    
    .input-group {
      padding: 115px 20px;
    }
    
    @include media-breakpoint-down(md) {
      background-position: center 45%;

      .input-group {
        padding: 105px 20px;
      }
    }
    
    @include media-breakpoint-up(lg) {
      background-position: center 45%;

      .input-group {
        padding: 85px 20px;
      }
    }

    // autocomplete search
    #search-box-results {
      width: 100%;
      inset: 80px auto auto !important;
    }
  }

  #top-bar .dropdown-item {
    color: $secondary;
    &:hover, &:active, &:focus {
      color: $white;
      background-color: $unfcccBlueHoverFocus !important;
    }
  }

  .input-group button {
    &:hover, &:active, &:focus {
      color: $white;
      border: $unfcccBlueHoverFocus !important;
      background-color: $unfcccBlueHoverFocus !important;
    }
  }

  .input-group .dropdown-menu .dropdown-item {
    &:hover, &:active, &:focus {
      color: $white;
      background-color: $unfcccBlueHoverFocus !important;
      text-decoration: underline $white;
    }
  }

}

footer {
  .footer-main p {
    font-size: 0.65 * $spacer;
    height: 15px;
    width: 90px;
  }

  .footer-main ul li a {
    font-size: 0.75 * $spacer;
    height: 45px;
    width: 45px;
  }

  .footer-ownership {
    width: 100%;
    margin: 0 auto;
    font-size: 0.65 * $spacer;
    color: $white;
  }
}

h1,
body.index #content h2,
body.index #content h2 button,
body.index #atom-digital-object-carousel .accordion-button {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.1;
}

#sidebar .accordion-button span {
  text-transform: uppercase;
}

#sidebar .card h2,
#sidebar .card h5,
.accordion-button,
.atom-section-header > * { // advanced search options
  position: relative;
  padding-bottom: $spacer * 1.5 !important;

  &::before {
    content: "";
    position: absolute;
    bottom: $spacer;
    width: $spacer * 3;
    border-top: $primary solid 3px;
  }
}

.accordion-button::after, .atom-section-header a::after {
  margin-top: $spacer * 0.5;
}